<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">{{$t('update.step1header')}}</h5>
        <div class="row justify-content-center">
          <div class="col-lg-10 text-center" v-html="$t('update.step1description')"></div>
        </div>
      </div>
  </form>
</ValidationObserver>
</template>
<script>




export default {
  data() {
    return {
     
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        return res;
      });
    }
  }
};
</script>
<style></style>
